import { useIntl } from 'react-intl';

import { useDeleteDeviceFromShop } from '../../../api/hooks/useShopApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { CancelButton } from '../../library/buttons/CancelButton';
import { ConfirmButton } from '../../library/buttons/ConfirmButton';
import { Dialog } from '../../library/dialog/Dialog';

export function DeleteDeviceEntityDialog() {
  const intl = useIntl();
  const { setOpenDeleteDialog, openDeleteDialog, entitieIdsToDelete, setEntitieIdsToDelete } =
    useCrudPageUiStateContext();
  const deleteDevice = useDeleteDeviceFromShop();

  const isShopEntity = () => entitieIdsToDelete?.[1] === 'shop' ?? false;

  return (
    <>
      {openDeleteDialog && !isShopEntity() && (
        <Dialog
          id={'DeleteDeviceEntityDialog'}
          isOpen={openDeleteDialog}
          title={intl.formatMessage({ id: 'shop.device.delete' })}
          content={intl.formatMessage({ id: 'shop.device.delete.confirmation' })}
          buttons={
            <>
              <CancelButton
                onClick={() => {
                  setOpenDeleteDialog(false);
                  console.log('Closes via abort');
                }}
              />
              <ConfirmButton
                onClick={() => {
                  if (entitieIdsToDelete) {
                    console.log(
                      'Delete device',
                      entitieIdsToDelete[0],
                      'from shop',
                      entitieIdsToDelete[1]
                    );
                    deleteDevice.mutate({
                      shopId: entitieIdsToDelete[0],
                      deviceId: entitieIdsToDelete[1],
                    });
                    console.log('Closes');
                    setEntitieIdsToDelete(undefined);
                    setOpenDeleteDialog(false);
                  }
                }}
              />
            </>
          }
        />
      )}
    </>
  );
}
