import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { PairingCode, Shop } from '../../types/shops/Shop';
import { ShopParameter } from '../../types/shops/ShopParameter';

export const SHOP_ROUTE = 'shops';
export const SHOP_QUERY_KEY = 'shops';

export function useCreateShop() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<ShopParameter>) =>
      client(SHOP_ROUTE, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SHOP_QUERY_KEY);
      },
    }
  );
}
export function useShops() {
  const client = useClient<Shop[]>();
  const result = useQuery(SHOP_QUERY_KEY, () => client(SHOP_ROUTE));
  const shops = result.data?.data ?? [];

  return {
    ...result,
    shops: shops,
  };
}
export function useGetPairingCode(shopId: string) {
  const client = useClient<PairingCode>();
  const result = useQuery(
    ['SHOP_QUERY_KEY', shopId],
    () => client(`/shops/${shopId}/pairingCode`).then(response => response.data),
    {
      enabled: !!shopId,
    }
  );

  return {
    ...result,
  };
}
export function useDeleteShop() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ shopId }: { shopId: string }) =>
      client(`/shops/${shopId}`, {
        method: 'DELETE',
        data: { shopId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SHOP_QUERY_KEY);
      },
    }
  );
}
export function useDeleteDeviceFromShop() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ shopId, deviceId }: { shopId: string; deviceId: string }) =>
      client(`/shops/${shopId}/devices/${deviceId}`, {
        method: 'DELETE',
        data: { shopId, deviceId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SHOP_QUERY_KEY]);
      },
    }
  );
}
